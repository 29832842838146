import './App.scss';

import Home from './pages/Home/Home';

function App() {

  return (
    <Home />
  );
}

export default App;
