import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "why": "Why",
      "why_large": "Why",
      "what": "What",
      "what_large": "What",
      "how": "How",
      "how_large": "How",
      "who": "Join Us",
      "team": "Our Team",
      "team_large": "Our Team",
      "title_home": " Innovative solutions for & by parents and caregivers",
      "paragraph_home": "GroeiSpurt is a new initiative aimed at structurally improving the position of parents and caregivers in vulnerable situations. In the Netherlands: Tilburg and Amsterdam Zuidoost, we are starting this in two regions. Our focus is on initiatives for parents and caregivers in vulnerable situations who are not adequately supported by the current system.",
      "email_submit": "Enter your email",
      "submit_button": "Submit",
      "submit_description": "Sign up to our mailing list and receive notifications on upcoming events and materials.",
      "why_title": "Why is more support for parents and caregivers needed in the Netherlands? ",
      "wallet_paragraph": "Raising children is a rewarding, yet also challenging task for parents and caregivers. It is often helpful to receive support in this, so that children can have the best possible start in life. <br> The Netherlands is one of the wealthiest countries in the world. In 2021, 165,000 children in the Netherlands (1 in 15) grew up in poverty. It appears that many families experience various sources of stress. There are also other challenges that affect the physical and mental health of parents and children. Some families feel isolated or feel that they do not receive enough support with the responsibilities that come with raising children.",
      "cloud_paragraph": "Many other families experience a range of sources of stress or challenges with physical and mental health of themselves or their children, some families may feel isolated or that they do not have enough support with parenting responsibilities.",
      "pig_paragraph": "Parenting is immensely rewarding but hard. There is no manual to be a parent or caregiver. Parenting and caregiving brings many challenges. Challenges may be exacerbated through financial difficulties, child mental health difficulties, parental/caregiver physical and mental illness, an absence of childcare, barriers to accessing services, and transitions. When parents and caregivers are mentally and physically well this has a positive impact on the mental and emotional health of children. This is because when parents are supported they are better able to provide responsive care to balance the needs of families.",
      "what_title": "What change is the incubator aiming to create for families?",
      "what_description": "GroeiSpurt is a project that helps parents, caregivers, and organizations with financial support and expertise, enabling them to set up initiatives to assist parents and caregivers who could use some extra help. <br>Growth Spurt aspires to demonstrate that complex challenges can be rapidly tackled by a small, determined group of actors designing innovative programmes and interventions. Our goal is to rapidly develop, test, and deploy interventions for improving the lives of 4,000 parents and caregivers within 12 months in two regions of the Netherlands. <br><br><b>What kind of solutions should I think of?</b><br><br> The ideas supported by GroeiSpurt aim to contribute to various aspects of the well-being of parents and caregivers. Think of physical health and well-being, parenting skills and confidence, support and childcare, psychological well-being of parents, support during the perinatal period, coping with stress, and protection against mental health issues.",
      "how_title": "How will the incubator work?",
      "how_description": "<b>We are looking for 45 participants to take on this challenge.</b> <br><br>Growth Spurt will form a team of 45 dedicated individuals (parents, caregivers, social organizations, governments) who will work in action-oriented six-month cycles to develop innovative solutions. During each action cycle, this team will split into four groups. Each group will have the opportunity to develop innovative, accessible, and rapid interventions that support parents and caregivers in raising children. A specially created innovation fund will help bring the developed ideas to life. The Growth Spurt fund enables parents and caregivers to finance new innovative projects.",
      "who_title": "Growth Spurt is convened by the Van Leer Foundation and Ten in Ten. Over a six month period, we are extending an invitation to partners, established and new, to join Growth Spurt.",
      "who_subtitle": "We are seeking up to 45 individuals to join, including parents and caregivers, community-based organisations, civil society groups, as well as local and national government representatives. Caregivers include stepparents, aunties, grandparents, and any others that are directly involved in the care of children. These individuals will find partners and prototype new interventions that aim to improve parental wellbeing for a target population in the Netherlands. The scope of the incubator is broad to enable a range of stakeholders to participate.",
      "who_description": "Our intention is supporting diverse facets of parental and caregiver wellbeing. <b>These include:</b>",
      "who_list": "<li>Physical Health</li><li>Wellbeing</li><li>Parenting Skills and Confidence </i><li>Support and Childcare</li><li>Parental Psychological Wellbeing</li><li>Support in the Perinatal Period</i><li>Managing Stress</li><li>Protecting Against Mental Health Difficulties.</li>",
      "who_footer": "In Growth Spurt, participants will have the opportunity to either build new interventions or strengthen existing ones that improve parental and caregiver wellbeing.",
      "foundation_description": "Since 1949, the Van Leer Foundation has been dedicated to supporting young children, caregivers and communities worldwide. We work with our partners to achieve large-scale impact for inclusive societies.",
      "ten_description": "Our mission is to tackle ten complex challenges in ten years. We do this through building demonstrations - practical, real-world responses creating real benefits for real people. ",
      "footer_description": "Follow us on social media: ",
      "footer_email_description": "Sign up to our mailing list and receive notifications on upcoming events and materials.",
      "footer_copy": "Growth Spurt Incubator 2024 ©",
      "learn_more": "Learn More",
      "why_copy": "",
      "team_description": "\"Our diverse team brings expertise in systems change, facilitation, evidenced based research, parenting interventions and community led initiatives.\"",
      "member0_title": "Manish Jain",
      "member0_role": "Strategic Advisor",
      "member0_description": "Manish is a social innovator and ecosystem manifestor with 25 years of experience working with grassroots leaders and communities. He co-founded Ten in Ten, Shikshantar, Swaraj University, Ecoversities Alliance, and Jail University. Previously, he worked at Morgan Stanley, UNESCO, and UNICEF. Manish holds degrees from Harvard University and Brown University.",
      "member0_image": "HomeImageSmall",
      "member1_title": "Zaid Hassan",
      "member1_role": "Project Director",
      "member1_description": "Zaid is a strategist, facilitator, and writer with 25 years of experience in tackling complex global challenges.  Co-founder of 10-in-10 and Gigatonne, Zaid is also the author of 'The Social Labs Revolution' and an upcoming book on evolution. He has advised clients including the World Bank, UN Foundation, and governments worldwide.",
      "member1_image": "HomeImageSmall",
      "member2_title": "Diana Finch",
      "member2_role": "Financial Controller",
      "member2_description": "With a career beginning in bookkeeping and accounting systems, she has held senior roles in socially and environmentally focused non-profits since 2001. Diana oversees financial management at 10-in-10. Former Managing Director of Bristol Pound, she authored 'Value Beyond Money' (2024), exploring innovative economic solutions.",
      "member2_image": "",
      "member3_title": "Mia Eisenstadt",
      "member3_role": "Head of Early Childhood Development & Growth Spurt Lead",
      "member3_description": "Dr Mia Eisenstadt is a psychologist, researcher, and award-winning entrepreneur with a PhD in child and adolescent mental health. She co-founded Reos Partners and created the Fatherli app. As the leader of the Growth Spurt project, Mia specializes in incubators and digital mental health solutions, with extensive experience in organizational development and multi-stakeholder collaborations. Mia's publications can be access here.",
      "member3_image": "",
      "member4_title": "Hector Steenbergen",
      "member4_role": "Project Coordinator, Growth Spurt Incubator",
      "member4_description": "Hector Steenbergen is a social sciences researcher with strong interests in governance, development, and policy processes, focusing on organizational networks and community-based organizations. His practical experience in people-centered projects informs his work at Growth Spurt Incubator, emphasizing real-world outcomes.",
      "member4_image": "",
      "member5_title": "Sjoerd Louwaars",
      "member5_role": "Facilitation and Partnerships",
      "member5_description": "Sjoerd Louwaars, senior consultant at SIRIS Academic and leader of the Growth Spurt project at Ten in Ten, bridges academia and society to drive systemic impact. With a master's in Urban Development from TU Delft, he focuses on leadership development, innovation ecosystems, and challenge-based education. Sjoerd previously directed PLNT Leiden and contributed to Leiden University's Centre for Innovation.",
      "member5_image": "",
      "member6_title": "Christopher Baan",
      "member6_role": "Facilitation and Partnerships",
      "member6_description": "Christopher Baan is a programme manager, strategist, facilitator, and innovator with 12 years of experience in the (semi-)public domain. At Ten in Ten's Growth Spurt project, he leads multi-stakeholder innovation programmes. He studied international development, focusing on innovation, governance, and sustainability, and currently works at the Ministry of Internal Affairs of the Netherlands, tackling urban innovation as a 'dealmaker' for Agenda Stad.",
      "member6_image": "",
      "member7_title": "Shaun Liverpool",
      "member7_role": "Research Lead",
      "member7_description": "Dr. Shaun Liverpool is a Chartered Psychologist, Researcher, and Lecturer specializing in Child and Adolescent Mental Health & Wellbeing. With a PhD from University College London, his research focuses on digital and creative mental health interventions. Shaun's work, including Power Up for Parents and KidsMatter, is published in numerous peer-reviewed journals. He is a lecturer at Edge Hill University and the Anna Freud National Centre.",
      "member7_image": "",
      "member8_title": "Rebeca Argüello",
      "member8_role": "Project Manager",
      "member8_description": "With over 12 years of experience, Rebeca has managed impactful projects in technology, rural communities, education, culture, and social development. At 10-in-10, she supports climate crisis projects and early childhood initiatives in the Philippines and the Netherlands. Rebeca excels in strategic organization, cross-cultural communication, and executive leadership.",
      "member8_image": "",
      "member9_title": "Annemarie Poorterman",
      "member9_role": "Van Leer Foundation Country Representative",
      "member9_description": "Annemarie, an experienced programme manager and executive at VLF, excels in strategic innovation and social systems. On secondment from CHÔRA Design, she leads projects for UNDP, Canadian Government, and MOTI Foundation. She has also worked with the Red Cross, Start Network, and HALO Trust on funding mechanisms and civil society collaborations.",
      "member9_image": "",
      "member10_title": "Rosien van Cann-Hinke",
      "member10_role": "Communication and Translation",
      "member10_description": "Rosien is an experienced copywriter and strategic communications professional. She has worked as a strategic communication advisor for the Dutch Ministry of Education, Science and Art. Rosien also works as a coach with kids as an entrepreneur, she is passionate about supporting families with techniques such as mindfulness and meditation.",
      "member10_image": "",
    }
  },
  nl: {
    translation: {
      "Welcome to React": "Welkom bij React en react-i18next",
      "why": "Waarom",
      "why_large": "Waarom doen we dit?",
      "what": "Wat",
      "what_large": "Wat doen we met GroeiSpurt?",
      "how": "Hoe",
      "how_large": "Hoe gaan we te werk?",
      "who": "Doe Mee",
      "team": "Ons Team",
      "team_large": "Ons Team",
      "title_home": "Innovatieve oplossingen voor & door ouders en verzorgers",
      "paragraph_home": "GroeiSpurt is een nieuw initiatief dat de positie van ouders en verzorgers in een kwetsbare positie structureel wil verbeteren. In Nederland: Tilburg and Amsterdam Zuidoost starten we hiermee in twee regio's. Onze focus ligt op initiatieven voor ouders en verzorgers in kwetsbare situaties die niet voldoende worden ondersteund door het huidige systeem.",
      "email_submit": "E-mailadres",
      "submit_button": "Ja ik doe graag mee",
      "submit_description": "Schrijf je in voor de nieuwsbrief en ontvang informatie over nieuwe evenementen en materialen.",
      "why_title": "Opvoeden is een mooie, maar ook uitdagende taak voor ouders en verzorgers. Vaak is het fijn hierbij ondersteund te worden zodat kinderen de best mogelijke start in het leven kunnen krijgen.",
      "wallet_paragraph": "Nederland is een van de welvarendste landen ter wereld. In 2021 groeiden 165.000 kinderen in Nederland (1 op de 15) op in armoede. Het blijkt dat veel gezinnen verschillende bronnen van stress ervaren. Ook zijn er andere uitdagingen die de fysieke en mentale gezondheid van ouders en kinderen beïnvloeden. Sommige gezinnen voelen zich geïsoleerd of ervaren dat zij onvoldoende steun krijgen bij de verantwoordelijkheden die bij opvoeding komen kijken.",
      "cloud_paragraph": "<b>Uitdagingen van ouders en verzorgers</b> <br><br> Ouderschap is een bijzondere en dankbare taak, maar ook een uitdagende. Er is geen handleiding die vertelt welke stappen je moet volgen om een goede ouder of verzorger te zijn. De uitdagingen van het ouderschap worden vergroot door armoede, mentale en fysieke gezondheidsproblemen, een gebrek aan kinderopvang, een scheiding, onveiligheid in het gezin of als men moeilijkheden ondervindt bij het krijgen van ondersteuning van de overheid of andere hulpbronnen.",
      "pig_paragraph": "Veel andere gezinnen ervaren verschillende bronnen van stress of uitdagingen met de fysieke en mentale gezondheid van henzelf of hun kinderen. Sommige gezinnen kunnen zich geïsoleerd voelen of het gevoel hebben dat ze niet genoeg ondersteuning krijgen bij hun ouderlijke verantwoordelijkheden. <br>Tegelijkertijd weten we dat als ouders en verzorgers zich goed voelen, dit een positief effect heeft op het welzijn van kinderen.",
      "what_title": "GroeiSpurt is een project dat ouders, verzorgers en organisaties helpt met financiële steun en deskundigheid, zodat zij initiatieven kunnen opzetten om ouders en verzorgers die wat extra hulp kunnen gebruiken, te ondersteunen.",
      "what_description": "GroeiSpurt wil laten zien dat complexe uitdagingen snel kunnen worden aangepakt door met een kleine, toegewijde en betrokken groep mensen innovatieve programma's en interventies te ontwerpen, ontwikkelen en uitvoeren. <br> Ons doel is om binnen 12 maanden snel interventies te ontwikkelen, testen en implementeren die het leven van 4.000 ouders en verzorgers in twee regio's van Nederland verbeteren <br><br> <b>Aan welke oplossingen moet ik denken? </b> De ideeën die GroeiSpurt ondersteunt, hebben als doel bij te dragen aan verschillende facetten van welzijn van ouders en verzorgers. Denk aan fysieke gezondheid en welzijn, opvoedingsvaardigheden en zelfvertrouwen, steun en kinderopvang, psychologisch welzijn van ouders, steun in de perinatale periode, het omgaan met stress en bescherming tegen mentale gezondheidsproblemen.",
      "how_title": "We zoeken 45 deelnemers om deze uitdaging aan te gaan",
      "how_description": "GroeiSpurt gaat een team formeren van 45 betrokken mensen (ouders, verzorgers, maatschappelijke organisaties, overheden) die in actiegerichte cycli van zes maanden werken aan innovatieve oplossingen. Tijdens elke actiecyclus splitst dit team zich op in vier groepen. Elke groep krijgt de kans om innovatieve, laagdrempelige en snelle interventies te ontwikkelen die ouders en verzorgers ondersteunen bij de opvoeding. Een speciaal opgezet innovatiefonds helpt bij het realiseren van de ontwikkelde ideeën. Het GroeiSpurt fonds stelt ouders en verzorgers in staat om nieuwe innovatieve projecten te financieren. <br><br><br> In GroeiSpurt krijgen deelnemers de kans om ofwel nieuwe interventies te ontwikkelen of bestaande interventies te versterken die het welzijn van ouders en verzorgers vergroten.",
      "who_title": "GroeiSpurt is een incubator programma en een initiatief van de Van Leer Foundation en Ten in Ten. Gedurende een periode van zes maanden nodigen wij partners, zowel bestaande als toekomstige, uit om deel te nemen aan GroeiSpurt.",
      "who_subtitle": "Hoe kun je helpen? <br><br> We zoeken maximaal 45 mensen die mee willen doen, waaronder ouders/verzorgers, maatschappelijke organisaties en vertegenwoordigers van lokale en nationale overheden. Onder verzorgers vallen ook stiefouders, tantes, grootouders en anderen die direct betrokken zijn bij de zorg voor kinderen. Met elkaar gaan zij samenwerkingspartners vinden en nieuwe interventies ontwikkelen met als doel het welzijn van ouders in Nederland te verbeteren.  GroeiSpurt heeft de ambitie om het voor een breed scala aan belanghebbenden mogelijk te maken om deel te nemen aan dit initiatief. In GroeiSpurt kunnen deelnemers zowel nieuwe interventies ontwikkelen als bestaande interventies verbeteren. Alles met het doel het welzijn van ouders, verzorgers en daarmee ook de kinderen te verbeteren.",
      "who_description": "<b>Ja, dat wil ik!</b> <br> <br> <ul className='list-image-arrow list-test list-inside '> <li>Ja ik doe graag mee</li> <li> Stuur me meer informatie </li> </ul><br> Onze missie is het welzijn van ouders en verzorgers te ondersteunen. We werken samen aan voorwaarden die hiervoor nodig zijn zodat ouders en verzorgers zich goed voelen en de juiste steun krijgen. Daarbij besteden we aandacht aan aspecten zoals: ",
      "who_list": "<li>Fysieke gezondheid en welzijn</li><li>Zelfvertrouwen en vaardigheden als ouder/opvoeder/verzorger</li><li>Ondersteuning en kinderopvang</li><li>Ondersteuning en kinderopvang</li><li>Het mentale welzijn van ouders</li><li>Extra zorg tijdens de perinatale periode</li><li>Omgaan met stress</li><li>Bescherming tegen mentale gezondheidsproblemen</li>",
      "who_footer": "",
      "foundation_description": "Sinds 1949 zet de Van Leer Foundation zich in om wereldwijd jonge kinderen, hun ouders, andere verzorgers en gemeenschappen te ondersteunen. In partnerschap leveren we een grote bijdrage aan meer inclusieve samenlevingen.",
      "ten_description": "Onze missie is om tien complexe uitdagingen in tien jaar aan te pakken. Dit doen we door aan de slag te gaan met realistische, praktische oplossingen die een positief verschil maken voor echte mensen. We zetten onze ideeën in de praktijk door initiatieven op te zetten die een wezenlijke een bijdrage leveren.",
      "footer_description": "Volg ons op social media: ",
      "footer_email_description": "Schrijf je in voor de nieuwsbrief en ontvang informatie over nieuwe evenementen en materialen.",
      "footer_copy": "Groei Spurt Incubator 2024 ©",
      "learn_more": "Meer Informatie",
      "why_copy": "Bron: NJi<a href='https://www.nji.nl/cijfers/armoede-gezinnen'>https://www.nji.nl/cijfers/armoede-gezinnen</a>",
      "team_description": "\"Ons multidisciplinaire team heeft expertise in systeemverandering, op feitelijke gegevens gebaseerd onderzoek en faciliteren maar ook in ouderschapsinterventies en initiatieven die door de gemeenschap worden geleid.\"",
      "member0_title": "Manish Jain",
      "member0_role": "Srrategisch adviseur",
      "member0_description": "Manish is een sociale innovator en ecosysteemmanifestor met 25 jaar ervaring in samenwerking met grassroots leiders en gemeenschappen. Hij mede-oprichtte Ten in Ten, Shikshantar, Swaraj University, Ecoversities Alliance en Jail University. Eerder werkte hij bij Morgan Stanley, UNESCO en UNICEF. Manish heeft diploma's van Harvard University en Brown University.",
      "member0_image": "HomeImageSmall",
      "member1_title": "Zaid Hassan",
      "member1_role": "Projectleider",
      "member1_description": "Zaid is een strateeg, facilitator en schrijver met 25 jaar ervaring in het aanpakken van complexe, wereldwijde uitdagingen. Als medeoprichter van 10-in-10 en Gigatonne, Zaid is ook de auteur van 'The Social Labs Revolution' en een aankomend boek over evolutie. Hij heeft advies gegeven aan klanten, waaronder de Wereldbank, de UN Foundation en overheden wereldwijd.",
      "member1_image": "HomeImageSmall",
      "member2_title": "Diana Finch",
      "member2_role": "Financiële controller",
      "member2_description": "Diana's carrière begon in boekhouding en boekhoudsystemen, en sinds 2001 bekleedt ze senior functies in sociaal en ecologisch gerichte non-profits. Diana houdt toezicht op het financieel beheer bij 10-in-10. Als voormalig Managing Director van Bristol Pound schreef ze 'Value Beyond Money' (2024), waarin innovatieve economische oplossingen worden verkend.",
      "member2_image": "",
      "member3_title": "Mia Eisenstadt",
      "member3_role": "Hoofd Vroegtijdige Ontwikkeling van Kinderen & Growth Spurt Projectleider",
      "member3_description": "Dr. Mia Eisenstadt is een psycholoog, onderzoeker en bekroonde ondernemer met een PhD in geestelijke gezondheid van kinderen en adolescenten. Ze mede-oprichtte Reos Partners en creëerde de Fatherli-app. Als leider van het Growth Spurt-project is Mia gespecialiseerd in incubators en digitale geestelijke gezondheidsoplossingen, met uitgebreide ervaring in organisatieontwikkeling en samenwerking met meerdere belanghebbenden.",
      "member3_image": "",
      "member4_title": "Hector Steenbergen",
      "member4_role": "Projectcoördinator, Growth Spurt Incubator",
      "member4_description": "Hector Steenbergen is een sociaal wetenschapper met sterke interesses in governance, ontwikkeling en beleidsprocessen met een focus op organisaties en gemeenschapgebaseerde initiatieven. Zijn praktische ervaring in mensgerichte projecten informeert zijn werk bij Growth Spurt Incubator, met de nadruk op real-world uitkomsten.",
      "member4_image": "",
      "member5_title": "Sjoerd Louwaars",
      "member5_role": "Facilitering en Partnerschappen",
      "member5_description": "Sjoerd Louwaars, senior consultant bij SIRIS Academic en leider van het Growth Spurt-project bij Ten in Ten, overbrugt de kloof tussen academie en samenleving om systemische impact te realiseren. Met een master in Stedelijke Ontwikkeling van TU Delft  richt hij zich op leiderschapsontwikkeling, innovatie-ecosystemen en op uitdagingen gebaseerde educatie. Sjoerd was eerder directeur van PLNT Leiden en droeg bij aan het Centrum voor Innovatie van de Universiteit Leiden.",
      "member5_image": "",
      "member6_title": "Christopher Baan",
      "member6_role": "Facilitering en Partnerschappen",
      "member6_description": "Christopher Baan is een programmamanager, strateeg, facilitator en innovator met 12 jaar ervaring in de (semi-)publieke sector. Bij het Growth Spurt-project van Ten in Ten leidt hij innovatieprogramma's met meerdere belanghebbenden. Hij studeerde internationale ontwikkeling, met een focus op innovatie, governance en duurzaamheid, en werkt momenteel bij het Ministerie van Binnenlandse Zaken van Nederland, waar hij zich bezighoudt met stedelijke innovatie als 'dealmaker' voor Agenda Stad.",
      "member6_image": "",
      "member7_title": "Shaun Liverpool",
      "member7_role": "Onderzoeksleider",
      "member7_description": "Dr. Shaun Liverpool is een erkende psycholoog, onderzoeker en docent die zich richt op de geestelijke gezondheid en het welzijn van kinderen en adolescenten. Met een PhD van University College London richt zijn onderzoek zich op digitale en creatieve geestelijke gezondheidsinterventies. Shaun's werk, inclusief Power Up for Parents en KidsMatter, is gepubliceerd in talrijke peer-reviewed tijdschriften. Hij is docent aan Edge Hill University en het Anna Freud National Centre.",
      "member7_image": "",
      "member8_title": "Rebeca Agüello",
      "member8_role": "Projectmanager",
      "member8_description": "Met meer dan 12 jaar ervaring heeft Rebeca impactvolle projecten beheerd in technologie, plattelandsgemeenschappen, onderwijs, cultuur en sociale ontwikkeling. Bij 10-in-10 ondersteunt ze klimaatcrisisprojecten en vroege kinderinitiatieven in de Filipijnen en Nederland. Rebeca blinkt uit in strategische organisatie, interculturele communicatie en uitvoerend leiderschap.",
      "member8_image": "",
      "member9_title": "Annemarie Poorterman",
      "member9_role": "Van Leer Foundation Landvertegenwoordiger",
      "member9_description": "Annemarie, een ervaren programmamanager en uitvoerend directeur bij VLF, blinkt uit in strategische innovatie en sociale systemen. Vanuit CHÔRA Design leidt ze projecten voor UNDP, de Canadese regering en de MOTI Foundation. Ze heeft ook samengewerkt met het Rode Kruis, het Start Network en HALO Trust aan financieringsmechanismen en samenwerkingen met maatschappelijke organisaties.",
      "member9_image": "",
      "member10_title": "Rosien van Cann-Hinke",
      "member10_role": "Communicatie en Vertaling",
      "member10_description": "Rosien Hinke is een ervaren copywriter en strategisch communicatieprofessional. Ze heeft gewerkt als strategisch communicatieadviseur voor het Nederlandse Ministerie van Onderwijs, Cultuur en Wetenschap. Daarnaast werkt Rosien als coach voor kinderen en als ondernemer, en ze is gepassioneerd over het ondersteunen van gezinnen met technieken zoals mindfulness en meditatie.",
      "member10_image": "",
    }
  }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;